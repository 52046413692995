import React from 'react'
import IncomeTax from '../../components/IncomeTaxCalculator/IncomeTax'
import Layout from '../../components/Layout'
import { Seo } from '../../components/seo'


export default function IncomeTaxCalculator() {
    return (
      <>
        <Seo
          title='Income Tax Calculator'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />
        <Layout>
          <IncomeTax />
        </Layout>
      </>
    );
}
