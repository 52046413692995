import React from 'react';

const AboutForm = ({ nextStep, handleFormData, values }) => {
  return (
    <div className='row income-tax'>
      <div className='col-md-12 card'>
        <div className='col-md-12 mt-5'>
          <h4 className='section-header'>
            About Yourself<div className='bar'></div>
          </h4>
        </div>
        <hr />
        <div className='tax-input-block-border'>
          <form>
            <div className='row'>
              <div className='col-md-6 mt-4 mb-4'>
                <label htmlFor='Name' className='form-label'>
                  Name<span className='text-danger required'>*</span>
                </label>
                <input
                  type='text'
                  className='rent-input form-control'
                  id='name'
                  name='name'
                  required
                  autoComplete='off'
                  value={values.name}
                  onChange={handleFormData}
                  placeholder='Please Enter Your Name'
                />
              </div>
              <div className='col-md-6 mt-4 mb-4'>
                <label htmlFor='Email' className='form-label'>
                  Email<span className='text-danger required'>*</span>
                </label>
                <input
                  type='email'
                  className='rent-input form-control'
                  id='email'
                  name='email'
                  required
                  autoComplete='off'
                  value={values.email}
                  onChange={handleFormData}
                  placeholder='Please Enter Your Email'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-5'>
                <label htmlFor='Date Of Birth' className='form-label'>
                  Date Of Birth<span className='text-danger required'>*</span>
                </label>
                <input
                  type='date'
                  className='rent-input form-control'
                  id='dob'
                  name='dob'
                  required
                  autoComplete='off'
                  value={values.dob}
                  onChange={handleFormData}
                />
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor='Profession' className='form-label'>
                  Choose Your Profession
                </label>
                <select
                  name='profession'
                  value={values.value}
                  onChange={handleFormData}
                  className='form-select'
                  aria-label='Default select example'
                >
                  <option value=''>Select Your Profession</option>
                  <option value='Salaried_employee'>Salaried Employee</option>
                  <option value='Business_man'>Business Man</option>
                  <option value='Freelancer'>Freelancer</option>
                  <option value='Professional'>Professional</option>
                  <option value='Others'>Others</option>
                </select>
              </div>
            </div>
            <div className='col-md-12 '>
              <button
                className='btn btn-md form-next-btn btn-success m-2'
                onClick={nextStep}
              >
                Next <i className='bi bi-arrow-right'></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AboutForm;
