import React from 'react';
import Slider from './Slider';

export default function OtherIncome({
  nextStep,
  prevStep,
  handleFormData,
  values,
}) {
  const OtherIncomeData = {
    header: `Other Income`,
    sliders: [
      {
        label: `FD Interest`,
        name: `income_from_fd`,
        className: `income_from_fd`,
        id: `income_from_fd`,
        min: `0`,
        max: `1000000`,
        sliders_name: `income_from_fd`,
        slider_id: `customRange2`,
      },
      {
        label: `Saving A/C Interest`,
        name: `saving_interest`,
        className: `saving_interest`,
        id: `saving_interest`,
        min: `0`,
        max: `1000000`,
        sliders_name: `saving_interest`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'saving_interest_tooltip',
        tool_content: `You will get the deduction of same upto Rs.10,000/- under
         section 80TTA automatically. Just enter your Interest amount here.`,
        tool_img: true,
      },
      {
        label: `Any Other Interest Income`,
        name: `other_interest`,
        className: `other_interest`,
        id: `other_interest`,
        min: `0`,
        max: `1000000`,
        sliders_name: `other_interest`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'saving_interest_tooltip',
        tool_content: `Taxable Interest received from RD, NSC Post office account etc`,
        tool_img: true,
      },
      {
        label: `Any Other Income`,
        name: `other_income`,
        className: `other_income`,
        id: `other_income`,
        min: `0`,
        max: `1000000`,
        sliders_name: `other_income`,
        slider_id: `customRange2`,
      },
    ],
    handleFormData,
    values,
  };
  return (
    <div className='income-slider card'>
      <div className='col-md-12 col-sm-12 col-xs-12 range-outer clearfix'>
        <Slider data={OtherIncomeData} />
      </div>
      <div className='col-md-12 hra-btn float-end'>
        <button
          className='btn btn-sm  form-prev-btn btn-primary'
          onClick={prevStep}
        >
          <i className='bi bi-arrow-left'></i>Back
        </button>
        <button
          className='btn btn-md form-next-btn btn-success m-2'
          onClick={nextStep}
        >
          Next <i className='bi bi-arrow-right'></i>
        </button>
      </div>
    </div>
  );
}
