import React from 'react';
import Slider from './Slider';

export default function Deduction({
  nextStep,
  prevStep,
  handleFormData,
  values,
  handleSubmit,
}) {
  const DeductionData = {
    header: `Deductions`,
    sliders: [
      {
        label: `Section 80C `,
        name: `section80c`,
        className: `section80c`,
        id: `section80c`,
        min: `0`,
        max: `500000`,
        sliders_name: `section80c`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'section80c_tooltip',
        tool_content: `Maximum deduction allowed upto Rs. 1,50,000/-. 
        Enter total of your LIC premium, PPF, ELSS, Principal repayment of housing loan etc.`,
        tool_img: true,
      },
      {
        label: `Section 80D`,
        name: `section80d`,
        className: `section80d`,
        id: `section80d`,
        min: `0`,
        max: `500000`,
        sliders_name: `section80d`,
        slider_id: `customRange2`,
        tool_position: 'right',
        tool_id: 'section80d_tooltip',
        tool_content: ``,
        toolt_img: '/imgs/business/sliders/gst-slider.png',
        tool_img: true,
      },
      {
        label: `Section 80E`,
        name: `section80e`,
        className: `section80e`,
        id: `section80e`,
        min: `0`,
        max: `5000000`,
        sliders_name: `section80e`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'section80e_tooltip',
        tool_content: `Enter your interest amount of education 
        loan paid during the F.Y. Deduction is allowed u/s 80E irrespective of any maximum limit.`,
        tool_img: true,
      },
      {
        label: `Other Deduction`,
        name: `other_deduction`,
        className: `other_deduction`,
        id: `other_deduction`,
        min: `0`,
        max: `5000000`,
        sliders_name: `other_deduction`,
        slider_id: `customRange2`,
      },
    ],
    handleFormData,
    values,
  };
  return (
    <div className='income-slider card'>
      <form>
        <div className='col-md-12 col-sm-12 col-xs-12 range-outer clearfix'>
          <Slider data={DeductionData} />
        </div>
        <div className='col-md-12 hra-btn float-end'>
          <button
            className='btn btn-sm  form-prev-btn btn-primary'
            onClick={prevStep}
          >
            <i className='bi bi-arrow-left'></i>Back
          </button>
          <button
            className='btn btn-md form-next-btn btn-success m-2'
            onClick={handleSubmit}
          >
            Submit <i className='bi bi-arrow-right'></i>
          </button>
        </div>
      </form>
    </div>
  );
}
