import React, { useState, useEffect } from 'react';
import FAQAccord from '../GFlush';
import ContentImg from '../GSTTemp/ContentImg';
import ImgContent from '../GSTTemp/ImgContent';
import AboutForm from './AboutForm';
import Deduction from './Deductions';
import IncomeSlider from './IncomeSlider';
import ItcResult from './ItcResult';
import OtherIncome from './OtherIncome';

const IncomeTax = () => {
  /* Img Content Component Data */
  const rentIcData = {
    id: 'itc-works',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'How Does Income Tax Calculator Work?',
    image: '/imgs/registration/ipr/td7.png',
    alt_tag: 'Online Income Tax Calculator',
    points: [
      {
        head: '',
        content: `Name, email address, and source of income are among the
                 fundamental information to enter.`,
        icon: true,
      },
      {
        head: '',
        content: `Enter your income information, including salary, rental
                income, and other sources, on the next screen.`,
        icon: true,
      },
      {
        head: '',
        content: `Enter your other sources of income by clicking Next.`,
        icon: true,
      },
      {
        head: '',
        content: `Type in the deductions that apply to you.`,
        icon: true,
      },
      {
        head: '',
        content: `You can view your upcoming income tax payment by selecting Calculate.`,
        icon: true,
      },
      {
        head: 'Note:',
        content: `If a field doesn't relate, simply type "0."`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const rentCiData = {
    id: 'steps',
    background: 'main-content',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'How To Calculate Income Tax?',
    paragraph: ``,
    points: [
      {
        head: `Calculation for Salaried person`,
        content: `The entire remuneration of an employee is calculated as Basic
                pay plus HRA, Special Allowance, Transport Allowance, and any other supplementary
                components. Your leave travel allowance, HRA, and other parts of your pay are
                tax-free when deducted from this wage.`,
        icon: true,
      },
      {
        head: ``,
        content: `So, it is imperative that you have your pay stubs on hand and are
                aware of all the components of your compensation in order to
                compute your total income tax.`,
        icon: true,
      },
      {
        content: `Also, you should be prepared with all investment-related documentation
                so that you can compute your taxes after taking advantage of all applicable
                exemptions and deductions.`,
        icon: true,
      },
      {
        content: `With the use of an example, let's learn how to calculate
                taxes using both the new and old tax regimes: -`,
        icon: true,
      },
      {
        content: `Income from Salary - 9,20,000 after HRA and LTA benefits`,
        icon: true,
      },
      {
        content: `20,000 in other sources of income`,
        icon: true,
      },
      {
        content: `Deduction u/s 80C - 1,50,000,Deduction u/s 80D - 25,000,Deduction u/s 80CCD(1B) - 50,000.`,
        icon: true,
      },
      {
        content: `Hence, the total taxable income would be 6,65,000`,
        icon: true,
      },
    ],
    image: '/imgs/business/form-filing.png',
    alt_tag: 'Online Rent Receipt Generator',
  };
  /* FaQ data */
  var RentGenFAQ = [
    {
      header: `How can I use TODAYFILINGS to calculate income tax for the fiscal
            years 2021–22 and 2022–23 (i.e., 2022–23 and 2023–2024)?`,
      body: [
        {
          content: `Click the Income Tax Calculator Page `,
          icon: true,
        },
        {
          content: `Fill out the all required details`,
          icon: true,
        },
        {
          content: `Then Click Calculate to know income tax result.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header:
        'What is the eligibility criteria for using income tax calculator?',
      body: [
        {
          content: `This calculator can be used by any Indian resident who
                     receives a salary or has any other form of income to determine their income tax.`,
          icon: true,
        },
      ],
    },
    {
      header: `What is income tax ?`,
      body: [
        {
          content: `An income tax is a type of direct tax that the applicant must
                     pay based on their individual income or profit. In other words, it refers
                      to the tax imposed on income.`,
          icon: true,
        },
      ],
    },
    // 3
    {
      header: `When does income tax have to be paid?`,
      body: [
        {
          content: `When your income exceeds the Basic Exemption Limit, income tax is
                    due. Every year, the government specifies this cap. The only basic exemption
                    threshold is Rs. 2.5 lakhs. For those with incomes under 5 lakhs, there is a
                    rebate of Rs 12500 available under Section 87A.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `What time frame does the tax calculation cover?`,
      body: [
        {
          content: `Any income made between April 1 and March 31 is subject to tax.
                     The "prior year" is the year in which the income was earned, and the
                     "assessment year" is the year in which the tax is computed.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the advantages of doing your taxes in advance?`,
      body: [
        {
          content: `To be able to set aside the money you will need for paying taxes
                    at the time of submitting a return, you should calculate your taxes in advance.
                     Hence, you don't need to hustle to secure funds at the time of filing your return.
                      The other advantage is that it informs you if your taxes will increase or decrease
                       from the previous year.`,
          icon: false,
        },
      ],
    },
    {
      header: `How is income tax calculated in India?`,
      body: [
        {
          content: `In India, income tax is computed using the tax rates
                    and slabs established by the Finance Minister in the annual budget.`,
          icon: false,
        },
      ],
    },
    {
      header: `Does this tax calculator determine my final tax obligation?`,
      body: [
        {
          content: `Indeed, it uses the data you enter to determine your
                    tax due. The same can undoubtedly be used to calculate your taxes for
                     2021–2022 and 2022–2023. Your tax obligation will be roughly the same,
                      depending on the information that is actually available.
                    `,
          icon: false,
        },
      ],
    },
    {
      header: `Why should I use an online tool to calculate my income taxes?`,
      body: [
        {
          content: `It is simple to estimate your annual tax payment with the aid
                    of an online tool. The Tax2win calculator is available for use. It gives you a
                     quick, easy, and user-friendly approach to calculate your income tax liability
                     and gives you accurate results quickly.
                    `,
          icon: false,
        },
      ],
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmit, setIsSubmit] = useState(false);

  const [validationError, setValidationError] = useState({
    dob: '',
    name: '',
    email: '',
  });
  //state for form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    dob: '',
    profession: '',
    income_from_salaries: '0',
    income_from_business: '0',
    rental_income: '0',
    home_loan: '0',
    income_from_fd: '0',
    saving_interest: '0',
    other_interest: '0',
    other_income: '0',
    section80c: '0',
    section80d: '0',
    section80e: '0',
    other_deduction: '0',
  });
  // console.log(formData);

  const [oldRegData, setOldRegData] = useState({
    income_from_salaries: '',
    income_from_business: '',
    rental_income: '',
    home_loan: '',
    income_from_fd: '',
    saving_interest: '',
    other_interest: '',
    other_income: '',
    other_source: '',
    section80c: '',
    section80d: '',
    section80e: '',
    other_deduction: '',
    old_reg_taxable_income: '',
  });
  const [newRegData, setNewRegData] = useState({
    income_from_salaries: '',
    income_from_business: '',
    rental_income: '',
    home_loan: '',
    income_from_fd: '',
    saving_interest: '',
    other_interest: '',
    other_income: '',
    other_source: '',
    section80c: '',
    section80d: '',
    section80e: '',
    financial_year_taxable_income: '',
    asses_year_taxable_income: '',
    other_deduction: '',
    standard_deduction: '',
  });

  const [assesYearTax, setAssesYearTax] = useState({
    asses_basic_tax: '',
    asses_year_cess: '',
    asses_final_tax: '',
    asses_surcharge: '',
    asses_rebate: '',
  });
  const [finYearTax, setFinYearTax] = useState({
    fin_basic_tax: '',
    fin_year_cess: '',
    fin_final_tax: '',
    fin_surcharge: '',
    fin_rebate: '',
  });
  const [oldRegTax, setOldRegTax] = useState({
    basic_tax: '',
    total_tax_payable: '',
    cess: '',
    rebate: '',
    surcharge: '',
  });

  let income_from_salaries = parseInt(formData.income_from_salaries);
  let income_from_business = parseInt(formData.income_from_business);
  let rental_income = parseInt(formData.rental_income);
  let home_loan =
    parseInt(formData.home_loan) <= 200000
      ? parseInt(formData.home_loan)
      : 200000;
  let income_from_fd = parseInt(formData.income_from_fd);
  let saving_interests = parseInt(formData.saving_interest);
  let sInterest =
    parseInt(formData.saving_interest) <= 10000
      ? parseInt(formData.saving_interest)
      : 10000;
  let other_interest = parseInt(formData.other_interest);
  let other_income = parseInt(formData.other_income);
  // alert(other_income);
  let section80c =
    parseInt(formData.section80c) <= 150000
      ? parseInt(formData.section80c)
      : 150000;
  let section80d =
    parseInt(formData.section80d) <= 75000
      ? parseInt(formData.section80d)
      : 75000;
  let section80e = parseInt(formData.section80e);
  let other_deduction = parseInt(formData.other_deduction);
  let standard_deduction = income_from_salaries !== 0 ? 50000 : 0;
  // let income_from_savings = 10000;
  let rentalIncome = rental_income * 0.3;

  /* Age Calculation */
  // Get the user's birth date (replace with your own date)
  const birthDate = new Date(formData.dob);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference between the current date and the user's birth date
  const diffInMs = currentDate - birthDate;
  const diffInSec = diffInMs / 1000;
  const diffInMin = diffInSec / 60;
  const diffInHrs = diffInMin / 60;
  const diffInDays = diffInHrs / 24;
  const diffInYears = diffInDays / 365;

  // Round the age down to the nearest integer
  const age = Math.floor(diffInYears);
  /* Other source */
  let other_source =
    income_from_fd + saving_interests + other_interest + other_income;

  /* Year Calculation */
  const today = new Date();

  // Get the current year and month
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // Note: months are zero-indexed in JavaScript

  // Calculate the financial year
  const financialYear =
    currentMonth < 4 ? `${currentYear}-${currentYear + 1}` : currentYear;

  // Calculate the assessment year
  const assessmentYear = `${currentYear + 1}-${currentYear + 2}`;
  const Calc = () => {
    /* Old Regime */
    let taxable_income_add =
      income_from_salaries +
      income_from_business +
      rental_income +
      other_source;
    let taxable_income_sub =
      taxable_income_add -
      home_loan -
      section80c -
      section80d -
      section80e -
      other_deduction -
      rentalIncome -
      sInterest -
      standard_deduction;
    setOldRegData({
      income_from_salaries: income_from_salaries,
      income_from_business: income_from_business,
      rental_income: rental_income,
      home_loan: home_loan,
      saving_interest: sInterest,
      section80c: section80c,
      section80d: section80d,
      section80e: section80e,
      other_deduction: other_deduction,
      other_source: other_source,
      old_reg_taxable_income: taxable_income_sub,
    });
  };

  const newRegCalc = () => {
    let financialYear_taxable_income =
      income_from_salaries + income_from_business + other_source;
    let assesYear_taxable_income =
      financialYear_taxable_income - standard_deduction;
    setNewRegData({
      // income_from_salaries: income_from_salaries,
      // income_from_business: income_from_business,
      // rental_income: rental_income,
      // home_loan: home_loan,
      saving_interest: parseInt(0),
      standard_deduction: parseInt(0),
      section80c: parseInt(0),
      section80d: parseInt(0),
      section80e: parseInt(0),
      // saving_interest: saving_interests,
      other_deduction: parseInt(0),
      other_source: other_source,
      financial_year_taxable_income: financialYear_taxable_income,
      asses_year_taxable_income: assesYear_taxable_income,
      // financial_year: financialYear,
      // assessment_year: assessmentYear,
    });
  };

  /*  Old Regime Tax Slab Calculation*/
  let oldRegFinTaxPayable = oldRegData.old_reg_taxable_income;
  const OldRegTaxCalculate = () => {
    let taxPayable = 0;
    if (oldRegFinTaxPayable <= 250000) {
      taxPayable = 0;
    } else if (oldRegFinTaxPayable > 250000 && oldRegFinTaxPayable <= 500000) {
      taxPayable = (oldRegFinTaxPayable - 250000) * 0.05;
    } else if (oldRegFinTaxPayable > 500000 && oldRegFinTaxPayable <= 1000000) {
      taxPayable = (oldRegFinTaxPayable - 500000) * 0.2 + 12500;
    } else if (oldRegFinTaxPayable > 1000000) {
      taxPayable = (oldRegFinTaxPayable - 1000000) * 0.3 + 112500;
    }

    const oldTaxableIncome = Math.round(Math.max(0, taxPayable));
    /* tax rebate Calculation */
    // Taxable income
    let income = oldTaxableIncome;

    // Tax rebate
    let rebate = 0;

    if (age < 60) {
      if (oldRegData.old_reg_taxable_income <= 500000) {
        rebate = income;
      }
      // else if (income > 500000 && income <= 1000000) {
      //   rebate = 50000;
      // }
      else {
        rebate = 0;
      }
    } else if (age >= 60 && age < 80) {
      if (income <= 500000) {
        rebate = income;
      }
      // else if (income > 500000 && income <= 1000000) {
      //   rebate = 75000;
      // }
      else {
        rebate = 0;
      }
    } else {
      if (income <= 500000) {
        rebate = income;
      }
      // else if (income > 500000 && income <= 1000000) {
      //   rebate = 100000;
      // }
      else {
        rebate = 0;
      }
    }

    // alert(totalTax);
    let surcharge = 0;

    if (oldRegFinTaxPayable > 5000000 && oldRegFinTaxPayable <= 10000000) {
      surcharge = income * 0.1;
    } else if (
      oldRegFinTaxPayable > 10000000 &&
      oldRegFinTaxPayable <= 20000000
    ) {
      surcharge = income * 0.15;
    } else if (
      oldRegFinTaxPayable > 20000000 &&
      oldRegFinTaxPayable <= 50000000
    ) {
      surcharge = income * 0.25;
    } else if (oldRegFinTaxPayable > 50000000) {
      surcharge = income * 0.37;
    } else {
      surcharge = 0;
    }
    const oldSurcharge = surcharge;

    let Cess = 0;
    if (oldRegFinTaxPayable > 500000) {
      let totalAmount = oldTaxableIncome + oldSurcharge;
      Cess = totalAmount * 0.04;
    } else if (income < 500000) {
      Cess = 0;
    }
    const healthEduCess = Cess;
    const totalTaxPayable =
      oldTaxableIncome - rebate + healthEduCess + oldSurcharge;
    setOldRegTax({
      rebate: rebate,
      basic_tax: oldTaxableIncome,
      cess: healthEduCess,
      surcharge: oldSurcharge,
      total_tax_payable: totalTaxPayable,
    });
  };
  /*  assessment Year Tax Slab Calculation*/
  let newRegAssTaxPayable = newRegData.asses_year_taxable_income;
  const AssesYearTaxCalculate = () => {
    let taxPayable = 0;
    // tax slab 1
    if (newRegAssTaxPayable > 300000) {
      const slab1Income = Math.min(newRegAssTaxPayable, 600000) - 300000;
      taxPayable += slab1Income * 0.05;
    }

    // tax slab 2
    if (newRegAssTaxPayable > 600000) {
      const slab2Income = Math.min(newRegAssTaxPayable, 900000) - 600000;
      taxPayable += slab2Income * 0.1;
    }

    // tax slab 3
    if (newRegAssTaxPayable > 900000) {
      const slab3Income = Math.min(newRegAssTaxPayable, 1200000) - 900000;
      taxPayable += slab3Income * 0.15;
    }
    // tax slab 4
    if (newRegAssTaxPayable > 1200000) {
      const slab4Income = Math.min(newRegAssTaxPayable, 1500000) - 1200000;
      taxPayable += slab4Income * 0.2;
    }

    // tax slab 5
    if (newRegAssTaxPayable > 1500000) {
      const slab5Income = newRegAssTaxPayable - 1500000;
      taxPayable += slab5Income * 0.3;
    }

    const finalTaxSlab = Math.round(Math.max(0, taxPayable));
    /* tax rebate Calculation */
    // Taxable income
    let income = finalTaxSlab;

    // Tax rebate
    let rebate = 0;

    const assesRebate = rebate;

    // alert(totalTax);
    let surcharge = 0;
    if (newRegAssTaxPayable > 5000000 && newRegAssTaxPayable <= 5075000) {
      surcharge = (income - 697500) * 0.1;
    } else if (
      newRegAssTaxPayable > 5075000 &&
      newRegAssTaxPayable <= 5175000
    ) {
      surcharge = income * 0.1 - 2750;
    } else if (
      newRegAssTaxPayable > 5075000 &&
      newRegAssTaxPayable <= 10000000
    ) {
      surcharge = income * 0.1;
    } else if (
      newRegAssTaxPayable > 10000000 &&
      newRegAssTaxPayable <= 20000000
    ) {
      surcharge = income * 0.15;
    } else if (
      newRegAssTaxPayable > 20000000 &&
      newRegAssTaxPayable <= 50000000
    ) {
      surcharge = income * 0.25;
    } else if (newRegAssTaxPayable > 50000000) {
      surcharge = income * 0.37;
    } else {
      surcharge = 0;
    }
    const assesSurCharge = surcharge;
    let Cess = 0;
    if (newRegAssTaxPayable > 500000) {
      let totalAmount = finalTaxSlab + assesSurCharge;
      Cess = totalAmount * 0.04;
    } else if (income < 500000) {
      Cess = 0;
    }
    const assesYearCess = Cess;
    const assesFinalTax =
      finalTaxSlab + assesYearCess + assesSurCharge - assesRebate;
    setAssesYearTax({
      asses_basic_tax: finalTaxSlab,
      asses_year_cess: assesYearCess,
      asses_final_tax: assesFinalTax,
      asses_surcharge: assesSurCharge,
      asses_rebate: assesRebate,
    });
  };

  /*  Financial Year Tax Slab Calculation */
  let newRegFinTaxPayable = newRegData.financial_year_taxable_income;
  const FinancialYearTaxCalculate = () => {
    let taxPayable = 0;

    // tax slab 1
    if (newRegFinTaxPayable > 250000) {
      const slab1Income = Math.min(newRegFinTaxPayable, 500000) - 250000;
      taxPayable += slab1Income * 0.05;
    }

    // tax slab 2
    if (newRegFinTaxPayable > 500000) {
      const slab2Income = Math.min(newRegFinTaxPayable, 750000) - 500000;
      taxPayable += slab2Income * 0.1;
    }

    // tax slab 3
    if (newRegFinTaxPayable > 750000) {
      const slab3Income = Math.min(newRegFinTaxPayable, 1000000) - 750000;
      taxPayable += slab3Income * 0.15;
    }
    // tax slab 4
    if (newRegFinTaxPayable > 1000000) {
      const slab4Income = Math.min(newRegFinTaxPayable, 1250000) - 1000000;
      taxPayable += slab4Income * 0.2;
    }
    // tax slab 5
    if (newRegFinTaxPayable > 1250000) {
      const slab4Income = Math.min(newRegFinTaxPayable, 1500000) - 1250000;
      taxPayable += slab4Income * 0.25;
    }

    // tax slab 5
    if (newRegFinTaxPayable > 1500000) {
      const slab5Income = newRegFinTaxPayable - 1500000;
      taxPayable += slab5Income * 0.3;
    }

    // let taxPayable = 0;
    // // tax slab 1
    // if (newRegFinTaxPayable > 300000) {
    //   const slab1Income = Math.min(newRegFinTaxPayable, 600000) - 300000;
    //   taxPayable += slab1Income * 0.05;
    // }

    // // tax slab 2
    // if (newRegFinTaxPayable > 600000) {
    //   const slab2Income = Math.min(newRegFinTaxPayable, 900000) - 600000;
    //   taxPayable += slab2Income * 0.1;
    // }

    // // tax slab 3
    // if (newRegFinTaxPayable > 900000) {
    //   const slab3Income = Math.min(newRegFinTaxPayable, 1200000) - 900000;
    //   taxPayable += slab3Income * 0.15;
    // }
    // // tax slab 4
    // if (newRegFinTaxPayable > 1200000) {
    //   const slab4Income = Math.min(newRegFinTaxPayable, 15000000) - 1200000;
    //   taxPayable += slab4Income * 0.2;
    // }

    // // tax slab 5
    // if (newRegFinTaxPayable > 15000000) {
    //   const slab5Income = newRegFinTaxPayable - 15000000;
    //   taxPayable += slab5Income * 0.3;
    // }

    const finTaxSlab = Math.round(Math.max(0, taxPayable));
    /* tax rebate Calculation */
    // Taxable income
    let income = finTaxSlab;

    // Tax rebate
    let rebate = 0;

    const finalRebate = rebate;

    // alert(totalTax);
    let surcharge = 0;
    if (newRegFinTaxPayable > 5000000 && newRegFinTaxPayable <= 5125000) {
      surcharge = (income - 400000) * 0.1;
    } else if (
      newRegFinTaxPayable > 5125000 &&
      newRegFinTaxPayable <= 10000000
    ) {
      surcharge = income * 0.1;
    } else if (
      newRegFinTaxPayable > 10000000 &&
      newRegFinTaxPayable <= 20000000
    ) {
      surcharge = income * 0.15;
    } else if (
      newRegFinTaxPayable > 20000000 &&
      newRegFinTaxPayable <= 50000000
    ) {
      surcharge = income * 0.25;
    } else if (newRegFinTaxPayable > 50000000) {
      surcharge = income * 0.37;
    } else {
      surcharge = 0;
    }
    const finSurCharge = surcharge;
    let Cess = 0;
    if (newRegFinTaxPayable > 500000) {
      let totalAmount = finTaxSlab + finSurCharge;
      Cess = totalAmount * 0.04;
    } else if (income < 500000) {
      Cess = 0;
    }
    const finYearCess = Cess;
    const finFinalTax = finTaxSlab + finYearCess + finSurCharge + finalRebate;
    setFinYearTax({
      fin_basic_tax: finTaxSlab,
      fin_year_cess: finYearCess,
      fin_final_tax: finFinalTax,
      fin_surcharge: finSurCharge,
      fin_rebate: finalRebate,
    });
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    if (!formData.dob) {
      setValidationError({
        ...validationError,
        dob: 'Please Select DOB',
      });
      return;
    }
    if (!formData.name) {
      setValidationError({
        ...validationError,
        name: 'Please Enter Your Name',
      });
      return;
    }
    if (!formData.email) {
      setValidationError({
        ...validationError,
        email: 'Please Enter Your Email',
      });
      return;
    }

    setCurrentStep(currentStep + 1);
  };
  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Head Navigation
  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (e) => {
    // input value from the form
    const { name, value } = e.target;
    // if (formData.trim().length !== 0) {
    //   console.log('input value is NOT empty');
    // } else {
    //   console.log('input value is empty');
    // }
    //updating for data state taking previous state and then adding new value to create new object
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    OldRegTaxCalculate();
    FinancialYearTaxCalculate();
    AssesYearTaxCalculate();
  }, [isSubmit]);

  const handleButton = (e) => {
    setIsSubmit(true);
    Calc();
    OldRegTaxCalculate();
    FinancialYearTaxCalculate();
    AssesYearTaxCalculate();
    newRegCalc();
    nextStep();
  };

  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <AboutForm
            validationError={validationError}
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 2:
        return (
          <IncomeSlider
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // Only formData is passed as prop to show the final value at form submit
      case 3:
        return (
          <OtherIncome
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            // handleSubmit={handleSubmit}
            values={formData}
          />
        );
      case 4:
        return (
          <Deduction
            prevStep={prevStep}
            handleFormData={handleInputData}
            handleSubmit={handleButton}
            values={formData}
          />
        );
      // Final Result
      case 5:
        return (
          <ItcResult
            prevStep={prevStep}
            standard_deduction={standard_deduction}
            financialYear={financialYear}
            assessmentYear={assessmentYear}
            other_source={other_source}
            formData={formData}
            homeLoan={home_loan}
            oldRegTax={oldRegTax}
            assesYearTax={assesYearTax}
            finYearTax={finYearTax}
            oldRegData={oldRegData}
            newRegData={newRegData}
            S_Interest={sInterest}
          />
        );
      // default case to show nothing
      default:
        return <div></div>;
    }
  };
  return (
    <>
      <section id='income-tax-calculator' className='house-rent'>
        <div className='container  mt-5'>
          <div className='row house-rent-receipt mt-5'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2'>
              <div className=' px-0 pt-4 pb-0 mt-5 mb-3'>
                {/* <h2 id='heading' className='mt-5'>
                            Rent Receipt Generator
                        </h2>
                        <p>Fill all form field to go to next step</p> */}
                <form id='msform'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='rent-text'>
                        <h1 className='rent-head section-header'>
                          Income Tax Calculator Free
                          <div className='bar'></div>
                        </h1>
                        <p className='rent-paragraph'>
                          You may estimate your taxes based on your income with
                          the simple-to-use income tax calculator. The planned
                          income tax adjustments in the Union Budget for
                          2023–2024 are now reflected in our tool.
                        </p>
                      </div>
                      <div className='rent-img'>
                        <img
                          src='/imgs/tax-3.png'
                          alt='IncomeTax Calculator'
                          className='img-fluid'
                        />
                      </div>
                    </div>
                    <div className='col-md-8 mt-5'>
                      <ul id='rent-progressbar'>
                        <li
                          className={currentStep === 1 ? 'active' : ''}
                          onClick={() => handleStepClick(1)}
                        >
                          <strong className='progress-text'>
                            About Yourself
                          </strong>
                        </li>
                        <li
                          className={currentStep === 2 ? 'active' : ''}
                          onClick={() => handleStepClick(2)}
                        >
                          <strong className='progress-text'>
                            Income Details
                          </strong>
                        </li>
                        <li
                          className={currentStep === 3 ? 'active' : ''}
                          onClick={() => handleStepClick(3)}
                        >
                          <strong className='progress-text'>
                            Other Income
                          </strong>
                        </li>
                        <li
                          className={currentStep === 4 ? 'active' : ''}
                          onClick={() => handleStepClick(4)}
                        >
                          <strong className='progress-text'>Deductions</strong>
                        </li>
                        <li
                          className={currentStep === 5 ? 'active' : ''}
                          onClick={() => handleStepClick(5)}
                        >
                          <strong className='progress-text'>Result</strong>
                        </li>
                      </ul>

                      {renderSwitch(currentStep)}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ImgContent item={rentIcData} />

      <ContentImg CISection_data={rentCiData} />

      <section className='salary-table mt-5'>
        <div className='container table-responsive'>
          <table className='table table-striped table-hover table-bordered'>
            <tbody className='text-center'>
              <tr className='bg-info'>
                <th className='text-white'>Salary Components</th>
                <th className='text-white'>Amount</th>
                <th className='text-white'>Deduction allowed</th>
              </tr>
              <tr>
                <th>Basic Salary</th>
                <td>800000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>HRA</th>
                <td>320000</td>
                <td>200000</td>
              </tr>
              <tr>
                <th>LTA</th>
                <td>10000</td>
                <td>10000</td>
              </tr>
              <tr>
                <th>Total CTC</th>
                <td>1130000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>Other income</th>
                <td>20000</td>
                <td>-</td>
              </tr>
              <tr>
                <th colSpan='3'>Investments</th>
              </tr>
              <tr>
                <th>80C</th>
                <td>150000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>80D</th>
                <td>25000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>80CCD(1B)</th>
                <td>50000</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className='itc-table'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-12 old-regime table-responsive'>
              <h3 className='section-header'>
                Tax calculation as per old regime<div className='bar'></div>
              </h3>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <td>Salary income</td>
                    <td>Amount</td>
                  </tr>
                  <tr>
                    <td>Basic</td>
                    <td>800000</td>
                  </tr>
                  <tr>
                    <td>HRA</td>
                    <td>320000</td>
                  </tr>
                  <tr>
                    <td>LTA</td>
                    <td>10000</td>
                  </tr>
                  <tr>
                    <th>Total CTC</th>
                    <th>1130000</th>
                  </tr>
                  <tr>
                    <td>HRA</td>
                    <td>200000</td>
                  </tr>
                  <tr>
                    <td>LTA</td>
                    <td>10000</td>
                  </tr>
                  <tr>
                    <td>Std. deduction</td>
                    <td>50000</td>
                  </tr>
                  <tr>
                    <td>Net taxable salary</td>
                    <td>870000</td>
                  </tr>
                  <tr>
                    <td>Other income</td>
                    <td>20000</td>
                  </tr>
                  <tr>
                    <th>Gross total income</th>
                    <th>890000</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-warning'>
                    <th colSpan='2'>Deduction</th>
                  </tr>
                  <tr>
                    <td>80C</td>
                    <td>150000</td>
                  </tr>
                  <tr>
                    <td>80CCD(1B)</td>
                    <td>50000</td>
                  </tr>
                  <tr>
                    <td>80D</td>
                    <td>25000</td>
                  </tr>
                  <tr>
                    <th>Total deduction</th>
                    <th>225000</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <th>Net taxable income</th>
                    <th>665000</th>
                  </tr>
                  <tr>
                    <th>Total tax on above</th>
                    <th>47320</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <td>Upto 250000</td>
                    <td>Upto nil</td>
                  </tr>
                  <tr>
                    <td>250000 to 500000</td>
                    <td>12500</td>
                  </tr>
                  <tr>
                    <td>balance 165000</td>
                    <td>33000</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th>47320</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='new-regime col-md-6 col-sm-12 table-responsive'>
              <h3 className='section-header'>
                Tax calculation as per new regime<div className='bar'></div>
              </h3>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <td>Salary income</td>
                    <td>Amount</td>
                  </tr>
                  <tr>
                    <td>Basic</td>
                    <td>800000</td>
                  </tr>
                  <tr>
                    <td>HRA</td>
                    <td>320000</td>
                  </tr>
                  <tr>
                    <td>LTA</td>
                    <td>10000</td>
                  </tr>
                  <tr>
                    <th>Total CTC</th>
                    <th>1130000</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <th>Net taxable salary</th>
                    <th>1130000</th>
                  </tr>
                  <tr>
                    <td>Other income</td>
                    <td>20000</td>
                  </tr>
                  <tr>
                    <th>Gross total income</th>
                    <th>1150000</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <th>Net taxable income </th>
                    <th>1150000</th>
                  </tr>
                  <tr>
                    <th>Total tax on above </th>
                    <th>109200</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-striped table-hover table-bordered bg-light'>
                <tbody className='text-center'>
                  <tr className='bg-info'>
                    <td>Up to 250000</td>
                    <td>nil</td>
                  </tr>
                  <tr>
                    <td>250000 to 500000</td>
                    <td>12500</td>
                  </tr>
                  <tr>
                    <td>500000 to 750000</td>
                    <td>25000</td>
                  </tr>
                  <tr>
                    <td>750000 to 1000000</td>
                    <td>37500</td>
                  </tr>
                  <tr>
                    <td>balance 150000</td>
                    <td>30000</td>
                  </tr>
                  <tr>
                    <td>Total tax</td>
                    <td>105000</td>
                  </tr>
                  <tr>
                    <td>Health &amp; Education Cess@ 4%</td>
                    <td>4200</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th>109200</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <FAQAccord items={RentGenFAQ} />
    </>
  );
};
export default IncomeTax;
