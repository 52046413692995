import React from 'react';
import Slider from './Slider';

export default function IncomeSlider({
  nextStep,
  handleFormData,
  values,
  prevStep,
}) {
  const IncomeSliderData = {
    header: `Income Details`,
    sliders: [
      {
        label: `Income from Salary / Pension`,
        name: `income_from_salaries`,
        className: `income_from_salaries`,
        id: `income_from_salaries`,
        min: `0`,
        max: `5000000`,
        sliders_name: `income_from_salaries`,
        slider_id: `customRange2`,
      },
      {
        label: `Income From Business / Profession / Freelancing`,
        name: `income_from_business`,
        className: `income_from_business`,
        id: `income_from_business`,
        min: `0`,
        max: `5000000`,
        sliders_name: `income_from_business`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'business_income_tooltip',
        tool_content: `You need to enter the Net Income i.e. in case of cash receipt income
        must be minimum of 8% of turnover or receipts and in case of Bank receipt, minimum 6% 
         of turnover or receipts. For Professionals, Income must be minimum 50% of gross receipts.`,
        tool_img: true,
      },
      {
        label: `Rental Income Received`,
        name: `rental_income`,
        className: `rental_income`,
        id: `rental_income`,
        min: `0`,
        max: `5000000`,
        sliders_name: `rental_income`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'rental_income_tooltip',
        tool_content:
          'Standard Deduction 30% will be provided on the amount entered automatically.',
        tool_img: true,
      },
      {
        label: `Interest Paid On Home Loan `,
        name: `home_loan`,
        className: `home_loan`,
        id: `home_loan`,
        min: `0`,
        max: `5000000`,
        sliders_name: `home_loan`,
        slider_id: `customRange2`,
        tool_pos: 'top',
        tool_id: 'rental_income_tooltip',
        tool_content: `You can claim deduction of interest amount upto Rs. 2,00,000/- in a F.Y.`,
        tool_img: true,
      },
    ],
    handleFormData,
    values,
  };
  return (
    <div className='income-slider card'>
      <div className='col-md-12 col-sm-12 col-xs-12 range-outer clearfix'>
        <Slider data={IncomeSliderData} />
      </div>
      <div className='col-md-12 hra-btn float-end'>
        <button
          className='btn btn-sm  form-prev-btn btn-primary'
          onClick={prevStep}
        >
          <i className='bi bi-arrow-left'></i>Back
        </button>
        <button
          className='btn btn-md form-next-btn btn-success m-2'
          onClick={nextStep}
        >
          Next <i className='bi bi-arrow-right'></i>
        </button>
      </div>
    </div>
  );
}
