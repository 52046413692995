import { Link } from 'gatsby';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ItcResult = ({
  standard_deduction,
  income_from_savings,
  assessmentYear,
  financialYear,
  other_source,
  formData,
  oldRegData,
  prevStep,
  newRegData,
  oldRegTax,
  finYearTax,
  assesYearTax,
  homeLoan,
}) => {
  return (
    <div>
      <div className='income-table'>
        <h1 className='rent-head section-header'>
          Income Tax Calculator Free
          <div className='bar'></div>
        </h1>

        <table className='table table-striped table-hover table-bordered bg-light'>
          <tbody>
            <tr className='bg-info text-center'>
              <th className='text-white'>Income Details</th>
              <th className='text-white'>Amount</th>
            </tr>
            <tr>
              <td className='td-text-left'>Salary</td>
              <td className='text-center'>
                {parseInt(formData.income_from_salaries).toLocaleString(
                  'en-IN',
                  {
                    style: 'currency',
                    currency: 'INR',
                    maximumFractionDigits: 0,
                  }
                )}
              </td>
            </tr>
            <tr>
              <td className='td-text-left'>Business</td>
              <td className='text-center'>
                {parseInt(formData.income_from_business).toLocaleString(
                  'en-IN',
                  {
                    style: 'currency',
                    currency: 'INR',
                    maximumFractionDigits: 0,
                  }
                )}
              </td>
            </tr>
            <tr>
              <td className='td-text-left'>
                House Property ( Rental Income Received )
              </td>
              <td className='text-center'>
                {parseInt(formData.rental_income).toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <td className='td-text-left'>
                House Property ( Interest Paid on Home Loan )
              </td>
              <td className='text-center'>
                {homeLoan.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <td className='td-text-left'>Other Income Sources</td>
              <td className='text-center'>
                {other_source.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
          </tbody>
        </table>
        {/* Taxable Income  */}
        <table className='table table-striped table-hover table-bordered bg-light'>
          <tbody>
            <tr className='bg-info text-center'>
              <th className='text-white'>Assessment Year</th>
              <th className='text-white'>
                {financialYear}
                <span> Old Regime (With Exemption)</span>
              </th>
              <th className='text-white'>
                {financialYear}
                <span> New Regime (Without Exemption)</span>
              </th>
              <th className='text-white'>
                {assessmentYear}
                <span> Old Regime (With Exemption)</span>
              </th>
              <th className='text-white'>
                {assessmentYear}
                <span> New Regime (Without Exemption)</span>
              </th>
            </tr>

            <tr>
              <th className='particulars'>80C Basic Deductions</th>
              <td>
                {oldRegData.section80c.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.section80c.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegData.section80c.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.section80c.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>

            <tr>
              <th className='particulars'>80D Medical Insurance Premium </th>
              <td>
                {oldRegData.section80d.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.section80d.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegData.section80d.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.section80d.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>80E Interest on Education Loan</th>
              <td>
                {oldRegData.section80e.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.section80e.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegData.section80e.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.section80e.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>

            <tr>
              <th className='particulars'>80TTA/80TTB Interest from Savings</th>
              <td>
                {oldRegData.saving_interest.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.saving_interest.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegData.saving_interest.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.saving_interest.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>Other Deductions</th>
              <td>
                {oldRegData.other_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.other_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegData.other_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.other_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>

            <tr>
              <th className='particulars'>
                Standard Deductions
                <span>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='standard_tooltip'>
                        Flat Rs. 50000/- Standard Deduction would be allowed to
                        all employees without submission of any proofs.
                      </Tooltip>
                    }
                  >
                    <img
                      src='/imgs/icons/it/info.png'
                      className='info_img'
                      alt='information'
                      // data-bs-toggle='tooltip'
                      // data-bs-placement='top'
                      // data-tooltip='Flat Rs. 50000/- Standard Deduction would be allowed to all employees without submission of any proofs.'
                    />
                  </OverlayTrigger>
                </span>
              </th>
              <td>
                {standard_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.standard_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {standard_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {standard_deduction.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>Taxable Income</th>
              <td>
                {oldRegData.old_reg_taxable_income.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.financial_year_taxable_income.toLocaleString(
                  'en-IN',
                  {
                    style: 'currency',
                    currency: 'INR',
                    maximumFractionDigits: 0,
                  }
                )}
              </td>
              <td>
                {oldRegData.old_reg_taxable_income.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {newRegData.asses_year_taxable_income.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
          </tbody>
        </table>

        {/* Tax Liability Table */}
        <table className='table table-striped table-hover table-bordered bg-light'>
          <tbody>
            <tr className='bg-info text-center'>
              <th className='text-white'>Assessment Year</th>
              <th className='text-white'>
                {financialYear}
                <span> Old Regime (With Exemption)</span>
              </th>
              <th className='text-white'>
                {financialYear}
                <span> New Regime (Without Exemption)</span>
              </th>
              <th className='text-white'>
                {assessmentYear}
                <span> Old Regime (With Exemption)</span>
              </th>
              <th className='text-white'>
                {assessmentYear}
                <span> New Regime (Without Exemption)</span>
              </th>
            </tr>

            <tr>
              <th className='particulars'>Basic Tax</th>
              <td>
                {oldRegTax.basic_tax.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {finYearTax.fin_basic_tax.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegTax.basic_tax.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {assesYearTax.asses_basic_tax.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>Rebate u/s 87A</th>
              <td>
                {oldRegTax.rebate.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {finYearTax.fin_rebate.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegTax.rebate.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {assesYearTax.asses_rebate.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>Surcharge</th>
              <td>
                {oldRegTax.surcharge.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {finYearTax.fin_surcharge.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegTax.surcharge.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {assesYearTax.asses_surcharge.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>Health & Education Cess (HEC)</th>
              <td>
                {oldRegTax.cess.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {finYearTax.fin_year_cess.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegTax.cess.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {assesYearTax.asses_year_cess.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
            <tr>
              <th className='particulars'>Total Tax Liability</th>
              <td>
                {oldRegTax.total_tax_payable.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {finYearTax.fin_final_tax.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {oldRegTax.total_tax_payable.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
              <td>
                {assesYearTax.asses_final_tax.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='row'>
          <div className='col-md-6 d-flex align-items-center justify-content-start'>
            <Link to='/' className='btn btn-md form-next-btn btn-success m-2'>
              File ITR Now <i className='bi bi-arrow-right'></i>
            </Link>
            {/* <Link to='/mortgages/itrfiling' className='btn btn-md form-next-btn btn-success m-2'>
              File ITR Now <i className='bi bi-arrow-right'></i>
            </Link> */}
          </div>
          <div className='col-md-6 d-flex align-items-center justify-content-end'>
            <button
              className='btn btn-sm  form-prev-btn btn-primary'
              onClick={prevStep}
            >
              <i className='bi bi-arrow-left'></i>Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItcResult;
